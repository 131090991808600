<template>
    <div class="content">
        <div class="right" style="padding: 2vh">
        <breadCrumb :siderContent="siderName" />
        <div class="searchForm">
          关键词:
          <a-input
            v-model="keywords"
            v-decorator="['keywords']"
            placeholder="姓名/手机号/卡号"
          />
          <span>
            状态：
            <a-select
              style="width: 9vw"
              @change="handleFreeze"
              v-model="statu"
            >
              <a-select-option value="1">正常</a-select-option>
              <a-select-option value="2">禁用</a-select-option>
            </a-select>
          </span>
          <span style="margin: 0 2vh;">
            等级：
            <a-select
              @change="handleGrade"
              v-model="grading"
            >
              <a-select-option  
                v-for="(item,index) in vipList"
                :key="index"
                :value="item.grade_id"
              >
              {{item.name}}
              </a-select-option>
            </a-select>
          </span>
          <a-button class="searchBtn" @click="searchMember()">查询</a-button>
          <a-button class="searchBtn" id="again" @click="againMember">重置</a-button>
          <br />
          <a-divider style="margin: 1vh" />
        </div>       
        <div class="center">
           <a-button class="smallOkBtn" style="padding:0 1vw;" @click="addClick">新增(F3)</a-button>
          <button class="bT" id="bT" @click="showBatchdisabled()">
            批量禁用
          </button>
          <button class="bT" @click="showBatchenabled()">
            批量启用
          </button>
        </div>
        <div>
          <a-table
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="members"
            :rowKey="
              (record, index) => {
                return record.user_id;
              }
            "
            bordered
            style="width: 100%; margin-top: 1vh"
            :pagination="{
              current: current,
              total: total,
              pageSize:8,
            }"
            @change="handleTableChange"
            :locale="{emptyText: '暂无数据'}"
          >
            <span slot="action" slot-scope="text, record">
              <span  class="Recharge" @click="showmodalRecharge(record)" v-if="record.is_freeze === 1 && is_entrance==1">充值</span>
              <!-- <span  class="Recharge" @click="showmodalRecharge(record)" v-if="record.is_freeze === 1">充值</span> -->
              <span  class=" jin"  v-if="record.is_freeze === 2">充值</span>
              <span  class=" jin"  v-if="is_entrance==0&&record.is_freeze === 1">充值</span>
              <!-- <span class="tui" @click="showmodalTui(record)" v-if="parseFloat(record.pay_money)>0 &&  record.is_freeze===1">退款</span>
              <span class=" jintui" v-if="parseFloat(record.pay_money)===0.00 || record.is_freeze===2">退款</span>     -->
            </span>
            <span slot="edit" slot-scope="text, record">
              <span  class="Recharge" @click="showEdit = true ; editData=record">编辑</span>
            </span>
            <span slot="use" slot-scope="text, item">
              <div v-if="item.is_freeze === 1 || item.is_freeze === true">
                正常
              </div>
              <div
                class="myUse"
                v-if="item.is_freeze === 2 || item.is_freeze === false"
              >
                禁用
              </div>
            </span>
            <span slot="grade" slot-scope="text, record">
              <span>{{record.grade&&record.grade.name}}</span>
            </span>
          </a-table>
          <!-- <button @click="getWeight" style="margin-right:20px;width:100px;height:50px">WeightCOM1 </button>
          <button @click="getWeights" style="margin-right:20px;width:100px;height:50px">WeightCOM2 </button>
          <button @click="getOpenDraw" style="margin-right:20px;width:100px;height:50px">openDrawCOM1</button>
          <button @click="getOpenDraws" style="margin-right:20px;width:100px;height:50px">openDrawCOM2</button> -->
        </div>
      </div>
    <Addmis :isShow="showAdd" @changeModal="changeAddModal" @chooseData="updateList"/>
    <Recharge :isShow="showRecharge" :fData="clickEveryGood" @changeModal="changeRechargeModal" @updateList="updatedListRe"/>
    <payBack :isShow="showPayback" :fData="clickEveryGood" @changeModal="changePayBack" @choose="updateListba"/>
    <editmis :isShow="showEdit" :editData="editData" @changeModal="changeEditModal" @chooseData="updateList" ></editmis>
    </div>
</template>
<script>
import Recharge from "./Recharge.vue";
import Addmis from "./Addmis.vue";
import payBack from "./payback.vue";
import breadCrumb from "@/components/BreadCrumb/BreadCrumb";
import editmis from './editmis.vue'
import {
  queryMembers,
  batchDisabled,
  batchEnabled,
  queryMembersgrade,
  rechargeSetting,
} from "@/request/mis";

const columns = [
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
  {
    title: "编辑",
    dataIndex: "edit",
    scopedSlots: { customRender: "edit" },
  },
  {
    title: "姓名",
    dataIndex: "nick_name",
  },
  {
    title: "手机",
    dataIndex: "mobile",
  },
  {
    title: "卡号",
    dataIndex: "card_number",
  },
  {
    title: "充值余额",
    dataIndex: "pay_money",
    ellipsis: true,
  },
  {
    title: "赠送余额",
    dataIndex: "gift_money",
    ellipsis: true,
  },

  {
    title: "总余额",
    dataIndex: "balance",
    ellipsis: true,
  },
   {
    title: "等级",
    dataIndex: "grade",
    scopedSlots: { customRender: "grade" },
  },
  {
    title: "状态",
    dataIndex: "is_freeze",
    scopedSlots: { customRender: "use" },
  },
];

export default {
  data() {
    return {
      members: [], //表格数据
      columns, //表头数据
      showRecharge: false, //充值页面
      // headerName: "会员系统",
      // sideMenu: [{ key: 0, value: "会员系统" },{ key: 1, value: "会员充值记录" }],
      siderName: "会员管理", //sider切换内容
      clickEveryGood: "",//点击时的每条会员信息
      selectedRowKeys: [], //选中数据
      keywords: "", //搜索关键词参数
      // userids: [], //批量禁用启用参数
      current: 1, //表格当前页
      total: 0, //表格数据条数
      formLayout: "horizontal", //表格属性
      form: this.$form.createForm(this, { name: "coordinated" }), //表格属性
      grade: '',//筛选等级
      freeze: '',//筛选状态
      showAdd:false,//新增会员modal
      statu:'全部状态',//状态框显示的字
      grading:'不限',//等级框显示的字
      showPayback:false,//退款modal
      vipList:[],//会员等级列表
      keywordsSure:'',//最终姓名手机号桌号
      gradeSure: '',//筛选等级
      freezeSure: '',//筛选状态
      scalar:{},
      is_entrance:0,//会员充值0关闭  1开启
      // is_custom:0,//自定义金额关闭 1开启
      editData:{},
      showEdit:false
    };
  },
  //vue的钩子函数，执行在vue挂在组件前，
  mounted() {
    this.membersRuquest();
    this.gradeList();
    this.keyUp();
    rechargeSetting ({
    }).then((res) => {
      if(res.data.status===200){
        this.is_entrance=res.data.data.is_entrance;
        // this.is_custom=res.data.data.is_custom;
      }     
    });

  },
  methods: {
    getWeight(){
      const weight=bound.getWeight("COM1", 9600);
      alert(weight)
    },
    getWeights(){
      const weight=bound.getWeight("COM2", 9600);
      alert(weight)
    },
    getOpenDraw(){
      bound.openDraw("COM1", 9600);
    },
    getOpenDraws(){
      bound.openDraw("COM2", 9600);
    },
     keyUp(){
        const _this=this;
        document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if(e1 && e1.keyCode == 114){
            _this.addClick();
            }
        }
    },
    updatedListRe(){//充值成功后更新列表
      this.membersRuquest();
    },
    againMember(){
      this.keywords="";
      this.grade='';
      this.freeze='';
      this.statu="全部状态";
      this.grading="不限";
      this.keywordsSure='',//最终姓名手机号桌号
      this.freezeSure='',//最终姓名手机号桌号
      this.gradeSure='',//最终姓名手机号桌号
      this.current=1;
      this.membersRuquest();
    },
    handleGrade(value) {
      this.grade = value;
    },
    handleFreeze(value) {
      this.freeze = value;
    },
    showChangefuzujian() {
      console.log("show change");
    },
    membersRuquest(){//会员列表请求
        queryMembers({
        keywords:this.keywordsSure,
        is_freeze: this.freezeSure,
        grade_id: this.gradeSure,
        listRows:8,
        page: this.current,
      }).then((res) => {
        if(res.data.status===200){
          const members = res.data.data.list.data && res.data.data.list.data;
          this.members = members;
          this.total = res.data.data.list.total && res.data.data.list.total;
        }     
      });
    },
    searchMember() {//点击查询按钮
      this.keywordsSure=this.keywords,//最终姓名手机号桌号
      this.freezeSure=this.freeze,//最终姓名手机号桌号
      this.gradeSure=this.grade,//最终姓名手机号桌号
      this.current=1;
      this.membersRuquest();
    },
    showBatchdisabled() {  //批量禁用
      batchDisabled({
         userids:this.selectedRowKeys
      }).then((res) => {
       if(res.data.status==200){
          this.membersRuquest();
          this.selectedRowKeys = [];
       }
      });
    
    },
    showBatchenabled() { // //批量启用
      batchEnabled({
        userids:this.selectedRowKeys
      }).then((res) => {
        if(res.data.status==200){
          this.membersRuquest();
          this.selectedRowKeys = [];
       }
      });
    },
    handleTableChange(e) {
      //点击分页
      this.current = e.current;
      this.membersRuquest();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    addClick(){ //点击新增按钮
      this.showAdd=true;
    },
    changeAddModal(){//关闭新增modal
     this.showAdd=false;
     this.keyUp()
    },
    changeEditModal(){//关闭编辑modal
     this.showEdit=false;
     this.keyUp()
    },
    changeRechargeModal(){//关闭充值modal
      this.showRecharge=false;
    },
    updateList(){ //新增会员后更新会员列表
      this.membersRuquest();
    },
    showmodalRecharge(record){//点击充值按钮
      this.showRecharge=true;
      this.clickEveryGood=record;
    },
    showmodalTui(record){ //点击退款
      this.showPayback=true;
      this.clickEveryGood=record;
    },
    changePayBack(){//关闭退款modal
      this.showPayback=false;
    },
    updateListba(){ //退款后更新列表
      this.membersRuquest();
    },
    gradeList(){
      const _this=this;
      queryMembersgrade ({
      }).then((res) => {
        if(res.data.status===200){
          _this.vipList=res.data.data.list.data && res.data.data.list.data ;
        }     
      });
    },

  },
  components: {
    // touTop,
    // sider,
    breadCrumb,
    Recharge,
    Addmis,
    payBack,
    editmis
  },

  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
};
</script>



<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bT {
  width: 13.3vh;
  height: 3.9vh;
  background-color:rgba(248, 248, 255, 1);;
  color: rgb(82, 79, 79);
  text-align: center;
  border-radius: 0vh 0.8vh 0.8vh 0vh;
  display: inline-block;
  cursor: pointer;
  font-size: 1.8vh;
  border: 1px solid rgb(212, 207, 207);
}
#bT {
  font-size: 1.8vh;
  margin-left: 12vw;
  border-radius: 0.8vh 0vh 0vh 0.8vh;
  border-right:0;
}

// .mis {
//   width: 100%;
//   display: flex;
//   height: 95.4vh;
// }
// .left {
//   width: 17vw;
//   background: rgba(46, 62, 78, 1);
// }
// .ipt {
//   width: 22vh;
//   height: 3.9vh;
//   padding: 1vh;
//   margin: 0vh 3vh 0 0vh;
// }

.myUse {
  color: #d0d0d0;
}
// .bTn {
//   width: 9vh;
//   height: 3.9vh;
//   margin-left: 4vh;
//   background-color: rgb(238, 121, 12);
//   border: none;
//   border-radius: 0.5vh;
//   color: white;
//   font-size: 1.8vh;
//   display: inline-block;
//   -webkit-transition-duration: 0.4s;
//   transition-duration: 0.4s;
// }
#again{
  margin-left: 1vh;
}
/deep/.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 0;
  font-size: 1.8vh;
  text-align: center;
}
/deep/.ant-table-thead > tr > th{
  background: rgba(248, 248, 255, 1);
}
/deep/.ant-table-tbody > tr > td {
  background: white;
}
/deep/.ant-select-enabled {
  width: 16.5vh !important;
}
/deep/.ant-select-selection--single {
  width: 16.5vh;
  height: 3.9vh;
}
/deep/.ant-select-selection__rendered {
  line-height: 3.75vh;
}
/deep/.ant-table-tbody > tr > td {
  padding: 8px 8px;
}
/deep/th:nth-child(2) {
  width: 9vw;
}
/deep/th:nth-child(3) {
  width: 7vw;
}
/deep/th:nth-child(4) {
  width: 9vw;
}
/deep/th:nth-child(5) {
  width: 13vw;
}
/deep/th:nth-child(6) {
  width: 7vw;
}
/deep/th:nth-child(7) {
  width: 6vw;
}
/deep/th:nth-child(9) {
  width: 7vw;
}
/deep/td {
  text-align: center;
  font-size: 1.8vh;
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-row {
  margin: 1.5vh;
}
/deep/.card-title {
  margin: 1.5vh;
  font-size: 2vh;
  margin-left: 0vw;
  font-weight: bold;
}

.Recharge{
  color: #2a82e4;
  cursor: pointer;
 
} 
.jin{
  color:#d0d0d0 !important;
} 
.right{
  background: rgba(248, 248, 255, 1);
}
.content{
  background: #f8f8ff;
}
/deep/.ant-select-selection-selected-value{
  font-size: 1.8vh;
}
</style>
