import request from "@/request/request";


//刘金隆apis
export function addMember(data) { //添加会员
    return request({
        url: "/clerk/user/add",
        method: "post",
        data: data,
    });
}
export function search(data) { //查询会员
    return request({
        url: "/clerk/user/search",
        method: "post",
        data: data,
    });
}

export function UserBill(data) { //查询会员流水
    return request({
        url: "/clerk/user/getUserBill",
        method: "post",
        data: data,
    });
}

export function queryRechargeResult(data) { //查询充值结果
    return request({
        url: "/clerk/user/rechargeRet",
        method: "post",
        data: data,
    });
}
export function recharge(data) { //会员充值
    return request({
        url: "/clerk/user/recharge",
        method: "post",
        data: data,
    });
}

export function queryRechargePackages(data) { //获取充值套餐
    return request({
        url: "/clerk/user/rechargePlan",
        method: "post",
        data: data,
    });
}
export function queryMembersgrade(data) { //查询会员等级列表
    return request({
        url: "/clerk/user/gradeList",
        method: "post",
        data: data,
    });
}
export function queryMembers(data) { //查询会员列表

    return request({
        url: "/clerk/user/list",
        method: "post",
        data: data,
    });
}
export function getGuides(data) { //获取店员列表
    return request({
        url: "/clerk/user/clerkList",
        method: "post",
        data: data,
    });
}
export function batchDisabled(data) { //批量禁用
    return request({
        url: "/clerk/user/freeze",
        method: "post",
        data: data,
    });
}
export function batchEnabled(data) { //批量启用
    return request({
        url: "/clerk/user/unfreeze",
        method: "post",
        data: data,
    });
}

export function editMember(data) { //编辑会员
    return request({
        url: "/clerk/user/edit",
        method: "post",
        data: data,
    });
}
export function refund(data) { //编辑会员
    return request({
        url: "/clerk/user/refund",
        method: "post",
        data: data,
    });
}
export function rechargeList(data) { //会员充值列表
    return request({
        url: "/clerk/user/rechargeList",
        method: "post",
        data: data,
    });
}
export function rechargeSetting(data) { //会员充值列表
    return request({
        url: "/clerk/user/rechargeSetting",
        method: "post",
        data: data,
    });
}

